import { IDataUserNotifications, IUserNotifications } from '../../types';

const convertNotifications = ({ count_news, count_feedback }: IDataUserNotifications) => {
    return {
        news: count_news,
        feedback: count_feedback,
    } as IUserNotifications;
};

export default convertNotifications;
