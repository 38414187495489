import {
    ICategorySettings,
    ICategorySettingsAttributeVirtual,
    ICategorySettingsAttributesCommonAttributes,
    ICategorySettingsAttributesCommonMain,
    IDataCategorySettings,
    IDataCategorySettingsAttributeVirtual,
    IDataCategorySettingsAttributesCommon,
} from '../../types';

const convertAttributesCommon = (attributes: IDataCategorySettingsAttributesCommon) => {
    const { fields_main, fields_attributes } = attributes;
    return {
        fieldsMain:
            fields_main?.map(field => {
                const { display_name, is_required, is_disabled, is_editable, ...rest } = field;
                return {
                    title: display_name,
                    required: is_required,
                    disabled: is_disabled,
                    editable: is_editable ?? true,
                    ...rest,
                } as ICategorySettingsAttributesCommonMain;
            }) ?? [],
        fieldsAttributes:
            fields_attributes?.map(field => {
                const { display_name, is_required, show_in_list, ...rest } = field;
                return {
                    title: display_name,
                    required: is_required,
                    showInList: show_in_list,
                    ...rest,
                } as ICategorySettingsAttributesCommonAttributes;
            }) ?? [],
    };
};

const convertAttributesVirtual = (attributes: IDataCategorySettingsAttributeVirtual[]) =>
    attributes.map(attr => {
        const { pim_identifier, is_required, ...rest } = attr;
        return { ...rest, pimId: pim_identifier, required: is_required } as ICategorySettingsAttributeVirtual;
    });

const convertCategorySettings = ({ category, common_attributes, virtual_attributes }: IDataCategorySettings) => {
    const { is_mandatory, sort, is_virtual, pim_identifier, ...restCategory } = category;
    const { existing_attributes, available_attributes } = virtual_attributes;
    const settings: ICategorySettings = {
        category: {
            required: is_mandatory ?? false,
            sort: sort ?? 0,
            isVirtual: is_virtual,
            pimId: pim_identifier,
            ...restCategory,
        },
        attributesCommon: convertAttributesCommon(common_attributes),
        attributesVirtual: {
            existingAttributes: convertAttributesVirtual(existing_attributes),
            availableAttributes: convertAttributesVirtual(available_attributes),
        },
    };
    return settings;
};

export default convertCategorySettings;
export { convertAttributesCommon, convertAttributesVirtual };
