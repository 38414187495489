import styled from '@emotion/styled';
import React from 'react';
import { DropdownIndicatorProps, components } from 'react-select';

const Indicator = styled.div<{ isOpen: boolean }>`
    transition: transform 0.5s ease-out;
    transform: ${({ isOpen }) => (isOpen ? 'rotateX(0)' : 'rotateX(180deg)')};
    margin-right: 2px;
`;

const DropdownIndicator = (props: DropdownIndicatorProps<any>) => {
    return (
        <components.DropdownIndicator {...props}>
            <Indicator isOpen={props.selectProps.menuIsOpen}>
                <svg width="10" height="12" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.26841 0.445099L9.82761 9.8225L0.709217 9.8225L5.26841 0.445099Z" fill="#cfcfcf" />
                </svg>
            </Indicator>
        </components.DropdownIndicator>
    );
};

export default DropdownIndicator;
