import React from 'react';
import { Outlet, Route } from 'react-router-dom';
import { IRoute } from '../assets/ts/types';
import RouteElement from './RouteElement';
import { PATH_AUTH } from './constants';

const setRoute = (route: IRoute) => {
    const { isActive, accessible, path, children } = route;
    const hasChildren = !!(children && children.length);

    return isActive && accessible ? (
        <Route path={path} element={hasChildren ? <Outlet /> : <RouteElement route={route} redirectTo={PATH_AUTH} />} key={`route-${route.id}`}>
            {hasChildren && (
                <>
                    <Route index element={<RouteElement route={route} redirectTo={PATH_AUTH} />} />
                    {children?.map(childRoute => setRoute(childRoute))}
                </>
            )}
        </Route>
    ) : (
        <></>
    );
};

export default setRoute;
