import { ICategoriesData, IRoute } from '../../assets/ts/types';
import { store } from '../index';
import { setCategories } from '../reducers/categories';
import { setRoutes as setRoutesReducer } from '../reducers/router';

const { dispatch } = store;

const updateCategories = (categories: ICategoriesData) => {
    dispatch(setCategories(categories));
};

const updateRoutes = (routes: IRoute[]) => {
    dispatch(setRoutesReducer(routes));
};

export { updateRoutes, updateCategories };
