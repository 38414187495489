import isEmail, { IsEmailOptions } from 'validator/lib/isEmail';
import * as Yup from 'yup';
import { AnySchema } from 'yup';
import { ALLOWED_IMG_FILE_EXTENSIONS, MAX_FILE_SIZE } from '../constants';
import { IDataFormValidation, IFormField, IFormFieldOption, IFormValues, TFileType, TValidationType } from '../types';
import formatBytes from './format-bytes';

const EMAIL_VALIDATION_CONFIG: IsEmailOptions = {
    allow_utf8_local_part: true,
    allow_underscores: true,
    require_tld: true,
};

const ERROR_REQUIRED = 'Обязательное поле';
const ERROR_NUMBER_INTEGER = 'Допустимо только целое значение';
const ERROR_NUMBER = 'Значение должно быть числом';
const ERROR_NUMBER_POSITIVE_ZERO = 'Значение не может быть меньше нуля';
const ERROR_NUMBER_POSITIVE = 'Значение должно быть больше нуля';
const ERROR_FORMAT = 'Неверный формат';
const ERROR_PASSWORD_LENGTH = 'Длина пароля должна быть не менее 6 символов';
const ERROR_PASSWORD_CONFIRM_MATCH = 'Пароли не совпадают';
const ERROR_COMPANY_INN_LENGTH = 'ИНН должен состоять из 10 или 12 цифр';
const ERROR_ARRAY_EMPTY = 'Выберите хотя бы одно значение';

const vrNumber = Yup.number()
    .transform((parsedValue, originalValue) => (originalValue === '' ? null : parsedValue))
    .nullable()
    .typeError(ERROR_NUMBER);
const vrNumberRequired = vrNumber.required(ERROR_REQUIRED);
const vrNumberPositiveZero = vrNumber.min(0, ERROR_NUMBER_POSITIVE_ZERO);
const vrNumberPositiveZeroRequired = vrNumberPositiveZero.required(ERROR_REQUIRED);
const vrNumberPositive = vrNumber.moreThan(0, ERROR_NUMBER_POSITIVE);
const vrNumberPositiveRequired = vrNumberPositive.required(ERROR_REQUIRED);
const vrInteger = vrNumber.integer(ERROR_NUMBER_INTEGER);
const vrIntegerRequired = vrInteger.required(ERROR_REQUIRED);
const vrIntegerPositiveZero = vrInteger.min(0, ERROR_NUMBER_POSITIVE_ZERO);
const vrIntegerPositiveZeroRequired = vrIntegerPositiveZero.required(ERROR_REQUIRED);
const vrIntegerPositive = vrInteger.moreThan(0, ERROR_NUMBER_POSITIVE);
const vrIntegerPositiveRequired = vrIntegerPositive.required(ERROR_REQUIRED);
const vrString = Yup.string()
    .transform((parsedValue, originalValue) => (originalValue === '' ? null : parsedValue))
    .nullable()
    .typeError(ERROR_FORMAT);
const vrStringRequired = vrString.required(ERROR_REQUIRED);
const vrBoolean = Yup.boolean();
const vrBooleanRequired = vrBoolean.oneOf([true], ERROR_REQUIRED);
const vrArray = Yup.array();
const vrArrayRequired = vrArray.min(1, ERROR_ARRAY_EMPTY);
const vrFile = (fileMaxSize: number = MAX_FILE_SIZE, fileAllowedExtensions: string[] = ALLOWED_IMG_FILE_EXTENSIONS, required: boolean = false) =>
    Yup.mixed()
        .test({
            message: ERROR_REQUIRED,
            test: (file: File) => !(required && !file),
        })
        .test({
            message: `Допустимый формат файла: ${fileAllowedExtensions.join(', ')}.`,
            test: (file, context) => {
                const { name } = file ?? {};
                const extension = name?.split('.').pop() || '';
                const isValid = file ? fileAllowedExtensions.includes(extension) || fileAllowedExtensions.includes(extension?.toLowerCase()) : true;
                if (!isValid) context?.createError();
                return isValid;
            },
        })
        .test({
            message: `Максимальный размер файла ${formatBytes(fileMaxSize)}.`,
            test: file => (file ? file?.size <= fileMaxSize : true),
        })
        .nullable();
const vrEmail = vrString.test({
    message: ERROR_FORMAT,
    test: value => (value ? isEmail(value, EMAIL_VALIDATION_CONFIG) : true),
});
const vrEmailRequired = vrEmail.required(ERROR_REQUIRED);

const validationRules = {
    vrNumber,
    vrNumberRequired,
    vrNumberPositiveZero,
    vrNumberPositiveZeroRequired,
    vrNumberPositive,
    vrNumberPositiveRequired,
    vrInteger,
    vrIntegerRequired,
    vrIntegerPositiveZero,
    vrIntegerPositiveZeroRequired,
    vrIntegerPositive,
    vrIntegerPositiveRequired,
    vrString,
    vrStringRequired,
    vrBoolean,
    vrBooleanRequired,
    vrArray,
    vrArrayRequired,
    vrFile,
    vrEmail,
    vrEmailRequired,
};
const validationErrors = {
    veRequired: ERROR_REQUIRED,
    veNumberInteger: ERROR_NUMBER_INTEGER,
    veNumber: ERROR_NUMBER,
    veNumberPositive: ERROR_NUMBER_POSITIVE,
    veFormat: ERROR_FORMAT,
    vePasswordLength: ERROR_PASSWORD_LENGTH,
    vePasswordMatch: ERROR_PASSWORD_CONFIRM_MATCH,
    veInnLength: ERROR_COMPANY_INN_LENGTH,
    veArrayRequired: ERROR_ARRAY_EMPTY,
};

const setFieldInitialValueAndSchema = ({
    initialValues,
    validationSchema,
    field,
    fieldKey,
}: {
    initialValues: IFormValues;
    validationSchema: { [name: string]: AnySchema };
    field: IFormField;
    fieldKey?: string;
}) => {
    const { name, value, validation } = field;
    const { type, required, regex, fileMaxSize, fileAllowedExtensions } = validation;
    const key = fieldKey ?? name;
    const isBool = ['checkbox', 'radio'].includes(type);
    const isMulti = ['multi', 'multi-not-unique'].includes(type);
    let schema: AnySchema;
    let currentValue: string | number | boolean | (string | number)[];

    switch (type) {
        case 'string':
        case 'textarea':
        case 'password':
            schema = required ? vrStringRequired : vrString;
            break;
        case 'float':
            schema = required ? vrNumberPositiveZeroRequired : vrNumberPositiveZero;
            break;
        case 'float-positive':
            schema = required ? vrNumberPositiveRequired : vrNumberPositive;
            break;
        case 'int':
            schema = required ? vrIntegerPositiveZeroRequired : vrIntegerPositiveZero;
            break;
        case 'int-positive':
            schema = required ? vrIntegerPositiveRequired : vrIntegerPositive;
            break;
        case 'checkbox':
        case 'radio':
            schema = required ? vrBooleanRequired : vrBoolean;
            break;
        case 'file':
            schema = vrFile(fileMaxSize, fileAllowedExtensions, required);
            break;
        case 'multi':
        case 'multi-not-unique':
            schema = required ? vrArrayRequired : vrArray;
            break;
    }

    if (regex) {
        schema = schema.test(name, validationErrors.veFormat, val => (val ? new RegExp(regex).test(val) : true));
    }

    switch (typeof value) {
        case 'object':
            if (isBool) {
                currentValue = (value ? value : false) as boolean;
            } else if (isMulti) {
                currentValue = (value ? value : []) as Array<string | number>;
            } else {
                currentValue = (value as IFormFieldOption)?.id || '';
            }
            break;
        case 'number':
            currentValue = value || value === 0 ? value : '';
            break;
        case 'boolean':
            currentValue = value || false;
            break;
        default:
            currentValue = value || '';
            break;
    }

    return {
        initialValues: { ...initialValues, [key]: currentValue },
        validationSchema: { ...validationSchema, [key]: schema },
    };
};

const convertValidation = (validation: IDataFormValidation) => {
    const TYPES: { [type: string]: TValidationType } = {
        str: 'string',
        positive_float: 'float-positive',
        positive_int: 'int-positive',
        num: 'float',
        bool: 'checkbox',
        list: 'multi',
        nu_list: 'multi-not-unique',
    };
    const { type } = validation;
    const fileValidation =
        type === 'file'
            ? {
                  fileType: 'image' as TFileType,
                  fileMaxSize: MAX_FILE_SIZE,
                  fileAllowedExtensions: ALLOWED_IMG_FILE_EXTENSIONS,
              }
            : {};
    return { ...validation, type: TYPES[type] ? TYPES[type] : (type as TValidationType), ...fileValidation };
};

export { validationRules, validationErrors, setFieldInitialValueAndSchema, convertValidation };
