import React, { ReactElement, createContext, useCallback, useState } from 'react';
import { IModal } from '../../assets/ts/types';

interface IModalContext {
    data: IModal;
    setData: (params: IModal) => void;
    closeModal: () => void;
}

const initModalData = {
    title: '',
    moduleName: '',
    props: {},
};

const initModalContext = {
    data: initModalData,
    setData: (params: IModal) => {},
    closeModal: () => {},
};

const ModalContext = createContext<IModalContext>(initModalContext);

const ModalProvider = ({ children }: { children: ReactElement }) => {
    const [modalContent, setModalContent] = useState<IModal>(initModalData);

    const setData = useCallback((params: any) => {
        setModalContent(params);
    }, []);

    const closeModal = useCallback(() => {
        setData(initModalData);
    }, [setData]);

    return <ModalContext.Provider value={{ data: modalContent, setData, closeModal }}>{children}</ModalContext.Provider>;
};

export default ModalContext;
export type { IModalContext };
export { initModalContext, ModalProvider };
