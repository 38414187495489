import { IDataUser, IDataUsersResponse, IUserItem } from '../../types';
import { convertRole } from './user-roles';

const convertUsersDifferentLoginData = ({ users, page, pages_total, prev, next, count }: IDataUsersResponse) => {
    const listUsers: Partial<IUserItem>[] = users.map((user: IDataUser) => {
        const { email, role, first_name, last_name } = user;
        return {
            email: email || '',
            firstName: first_name,
            lastName: last_name,
            ...convertRole(role),
        };
    });

    return {
        users: listUsers,
        usersTotal: count,
        page,
        pagesTotal: pages_total,
        pagePrev: prev,
        pageNext: next,
    };
};

export default convertUsersDifferentLoginData;
