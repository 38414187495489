import {
    IAppUserSettings,
    IAppUserSettingsColumn,
    IAppUserSettingsPages,
    IDataAppUserSettings,
    IDataAppUserSettingsColumn,
    IDataAppUserSettingsPages,
} from '../../types';

const convertColumnsSettings = (columns: IDataAppUserSettingsColumn[]) =>
    columns.map(col => ({ ...col, title: col.display_name, sortIndex: col.sort_index })) as IAppUserSettingsColumn[];

const convertUserSettings = (data: IDataAppUserSettings) => {
    const { pages } = data;

    const convertPagesSettings = (pages: IDataAppUserSettingsPages) => {
        const pagesSettings: IAppUserSettingsPages = {};
        Object.entries(pages).forEach(([pKey, pData]) => {
            const { columns, ...rest } = pData;
            pagesSettings[pKey] = { ...rest };
            columns && (pagesSettings[pKey].columns = convertColumnsSettings(columns));
        });
        return pagesSettings;
    };

    const settings: IAppUserSettings = { ...data, pages: convertPagesSettings(pages) };
    return settings;
};

export default convertUserSettings;
export { convertColumnsSettings };
