import { createSlice } from '@reduxjs/toolkit';
import { IStateSideNav } from '../../assets/ts/types';

const initialState: IStateSideNav = {
    opened: true,
};

const sideNavReducer = createSlice({
    name: 'sideNav',
    initialState,
    reducers: {
        toggleOpened(state, action) {
            return { ...state, opened: action.payload };
        },
    },
});

export const { toggleOpened } = sideNavReducer.actions;
export default sideNavReducer.reducer;
