/**
 * Получить положение элемента на странице (координаты верхней точки)
 *
 * @param element - элемент, координаты верхней точки на странице которого необходимо вычислить
 */

const getOffsetTop = (element: HTMLElement) => {
    let offsetTop = 0;

    while (element) {
        offsetTop += element.offsetTop;
        element = element.offsetParent as HTMLElement;
    }

    return offsetTop;
};

export default getOffsetTop;
