/**
 * Конвертирует строку в pascal case
 *
 * @param str - конвертируемая строка
 */

const convertToPascalCase = (str: string): string => {
    const splittedArray = str.split('_');

    if (splittedArray.length > 1) {
        return splittedArray.map((word, index) => (index > 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word)).join('');
    }

    return str;
};

export default convertToPascalCase;
