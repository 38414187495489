import React, { ComponentType, LazyExoticComponent, Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import lazyRetry from '../assets/ts/helpers/lazy-retry';
import { IRoute } from '../assets/ts/types';
import RoutePrivate from './RoutePrivate';

interface IProps {
    route: IRoute;
    redirectTo: string;
}

const outOfService = process.env.REACT_APP_OUT_OF_SERVICE === 'true';

const RouteElement = ({ route, redirectTo }: IProps) => {
    const { element, isPrivate } = route;
    const { name, type, to, replace, options } = element || {};
    const component =
        name === 'Navigate' && to && replace !== undefined ? (
            <Navigate to={to} replace={replace} />
        ) : (
            <Suspense fallback="Загрузка...">{setComponent(name, type, options)}</Suspense>
        );

    return !outOfService && isPrivate ? <RoutePrivate redirectTo={redirectTo}>{component}</RoutePrivate> : component;
};

function setComponent(name: string, type?: string, options?: { [name: string]: any }) {
    switch (type) {
        case 'products':
            return <Products categoryName={name} {...options} />;
        default:
            const Component = components[name];
            return <Component {...options} />;
    }
}

const Auth = lazy(() => lazyRetry(() => import(/* webpackChunkName: "auth" */ '../modules/auth'), 'auth'));
const AuthDifferentUser = lazy(() =>
    lazyRetry(() => import(/* webpackChunkName: "auth" */ '../modules/admin/auth-different-user'), 'auth-different-user')
);
const Registration = lazy(() => lazyRetry(() => import(/* webpackChunkName: "registration" */ '../modules/registration'), 'registration'));
const Restore = lazy(() => lazyRetry(() => import(/* webpackChunkName: "restore" */ '../modules/restore'), 'restore'));
const Profile = lazy(() => lazyRetry(() => import(/* webpackChunkName: "profile" */ '../modules/profile'), 'profile'));
const News = lazy(() => lazyRetry(() => import(/* webpackChunkName: "news" */ '../modules/news'), 'news'));
const NewsAdmin = lazy(() => lazyRetry(() => import(/* webpackChunkName: "news-admin" */ '../modules/admin/news'), 'news-admin'));
const ConfigurationsList = lazy(() =>
    lazyRetry(() => import(/* webpackChunkName: "configurations-list" */ '../modules/configurations-list'), 'configurations-list')
);
const ConfiguratorForm = lazy(() =>
    lazyRetry(() => import(/* webpackChunkName: "configuration-form" */ '../modules/configurator-form'), 'configuration-form')
);
const ConfigurationHistory = lazy(() =>
    lazyRetry(() => import(/* webpackChunkName: "configuration-history" */ '../modules/configuration-history'), 'configuration-history')
);
const Projects = lazy(() => lazyRetry(() => import(/* webpackChunkName: "projects" */ '../modules/projects'), 'projects'));
const AdminIndex = lazy(() => lazyRetry(() => import(/* webpackChunkName: "admin-index" */ '../modules/admin'), 'admin-index'));
const Users = lazy(() => lazyRetry(() => import(/* webpackChunkName: "users" */ '../modules/admin/users'), 'users'));
const AppSettings = lazy(() => lazyRetry(() => import(/* webpackChunkName: "app-settings" */ '../modules/admin/settings'), 'app-settings'));
const Associations = lazy(() => lazyRetry(() => import(/* webpackChunkName: "associations" */ '../modules/admin/associations'), 'associations'));
const Storages = lazy(() => lazyRetry(() => import(/* webpackChunkName: "storages" */ '../modules/admin/storages'), 'storages'));
const PricesMonitoring = lazy(() =>
    lazyRetry(() => import(/* webpackChunkName: "prices-monitoring" */ '../modules/admin/prices-monitoring'), 'prices-monitoring')
);
const FeedbackAdmin = lazy(() => lazyRetry(() => import(/* webpackChunkName: "feedback-admin" */ '../modules/admin/feedback'), 'feedback-admin'));
const ProductsIndex = lazy(() => lazyRetry(() => import(/* webpackChunkName: "products-index" */ '../modules/products'), 'products-index'));
const Products = lazy(() => lazyRetry(() => import(/* webpackChunkName: "products" */ '../modules/products/Products'), 'products'));
const Page404 = lazy(() => lazyRetry(() => import(/* webpackChunkName: "page-404" */ '../modules/page-404'), 'page-404'));

const components: { [name: string]: LazyExoticComponent<ComponentType<any>> } = {
    Auth,
    AuthDifferentUser,
    Registration,
    Restore,
    Profile,
    News,
    NewsAdmin,
    ConfigurationsList,
    ConfiguratorForm,
    ConfigurationHistory,
    Projects,
    AdminIndex,
    Users,
    AppSettings,
    Associations,
    Storages,
    PricesMonitoring,
    FeedbackAdmin,
    ProductsIndex,
    Products,
    Page404,
};

export default RouteElement;
