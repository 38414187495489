import { IDataNewsList, IDataNewsListItem, IDataNewsListItemDetail, INewsList, INewsListItem, INewsListItemDetail } from '../../types';

const convertNewsItem = (item: IDataNewsListItem) => {
    const { is_read, created_at, updated_at, ...rest } = item;
    return { ...rest, dateCreated: created_at, dateUpdated: updated_at, isNew: !is_read } as INewsListItem;
};

const convertNewsItemDetail = (item: IDataNewsListItemDetail) => {
    const { created_at, updated_at, ...rest } = item;
    return { ...rest, dateCreated: created_at, dateUpdated: updated_at } as INewsListItemDetail;
};

const convertNews = ({ news, next, prev, pages_total, count, ...rest }: IDataNewsList) =>
    ({
        news: news.map(i => convertNewsItem(i)),
        pagePrev: prev,
        pageNext: next,
        pagesTotal: pages_total,
        itemsTotal: count,
        ...rest,
    } as INewsList);

export default convertNews;
export { convertNewsItem, convertNewsItemDetail };
