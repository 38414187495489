import styled from '@emotion/styled';
import React from 'react';
import { SingleValueProps, components } from 'react-select';
import { IConfigurationFormProduct, ISelectOption } from '../../assets/ts/types';
import SingleValueContent from './SingleValueContent';

const Wrapper = styled.div<{}>``;

const SingleValue = ({ children, ...props }: SingleValueProps<IConfigurationFormProduct & ISelectOption>) => {
    return (
        <components.SingleValue {...props}>
            <Wrapper className="select__option-content">
                <SingleValueContent item={props.data} />
            </Wrapper>
        </components.SingleValue>
    );
};

export default SingleValue;
