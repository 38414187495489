/**
 * Функция для форматирования размера файла в байтах в читабельный формат
 *
 * @param bytes - размер файла в байтах
 * @param decimals - количество знаков после запятой
 *
 * @returns string
 *
 * */

const formatBytes = (bytes: number, decimals: number = 2) => {
    if (!+bytes) return '0 Б';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Б', 'КБ', 'МБ', 'ГБ', 'ТБ'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export default formatBytes;
