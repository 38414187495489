/**
 * Проверяет соответствие сохраненных в сторе данных в текущей сессии
 *
 * @param reducerName - наименование типа данных
 * @param reducerData - текущий набор данных из стора
 *
 */
import { store } from '../index';
import { clearStorage } from '../reducers/app';
import { initialState as initialStateUser } from '../reducers/user';

const checkStorage = (reducerName: string, reducerData: any) => {
    switch (reducerName) {
        case 'user':
            if (reducerData.id) {
                return !Object.keys(initialStateUser).some(key => {
                    if (!(key in reducerData)) {
                        store.dispatch(clearStorage());
                        return true;
                    }
                    return false;
                });
            }
            return true;
    }
    return true;
};

export default checkStorage;
