import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import ScrollToTop from './components/ScrollToTop';
import App from './modules/app';
import { persistor, store } from './store';
import { AppProvider } from './store/context/AppContext';
import { ModalProvider } from './store/context/ModalContext';
import './assets/scss/index.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter>
                    <ScrollToTop />
                    <AppProvider>
                        <ModalProvider>
                            <App />
                        </ModalProvider>
                    </AppProvider>
                </BrowserRouter>
            </PersistGate>
        </Provider>
    </React.StrictMode>
);
