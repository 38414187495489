import { IDataPricesMonitoringResponse, IPricesMonitoringResponse } from '../../types';

const convertPricesMonitoring = ({ prev, next, pages_total, items, count, ...rest }: IDataPricesMonitoringResponse) =>
    ({
        ...rest,
        pagePrev: prev,
        pageNext: next,
        pagesTotal: pages_total,
        itemsTotal: count,
        items: items.map(i => {
            const { display_name, price, configurator_price, prices_difference, app_id, category_name, pricelist_id, ...rest } = i;
            return {
                ...rest,
                title: display_name,
                priceRs: price,
                priceCustom: configurator_price,
                pricesDifference: prices_difference,
                appId: app_id,
                categoryName: category_name,
                articul: pricelist_id,
            };
        }),
    } as IPricesMonitoringResponse);

export default convertPricesMonitoring;
