import { ICategoriesData, IDataProduct, IDataProductsResponse, IProduct } from '../../types';
import convertSlots from './slots';

const convertProductsData = (response: IDataProductsResponse, categories?: ICategoriesData) => {
    const productsByCategory: { [name: string]: IProduct[] } = {};

    const products: IProduct[] = response.items.map((product: IDataProduct) => {
        const {
            id,
            name,
            naming,
            category_name: categoryName,
            price,
            price_percent,
            slots,
            attributes,
            additional_items,
            count,
            active,
            pricelist_id,
        } = product;

        const newProduct: IProduct = {
            id,
            name,
            naming,
            categoryName,
            price,
            pricePercent: price_percent,
            slots: convertSlots(slots),
            attributes,
            additionalItems: additional_items,
            count,
            active,
            articul: pricelist_id,
        };

        if (categories) {
            if (!productsByCategory[categoryName]) {
                productsByCategory[categoryName] = [newProduct];
            } else {
                productsByCategory[categoryName].push(newProduct);
            }
        }

        return newProduct;
    });

    return { products, productsByCategory };
};

export default convertProductsData;
