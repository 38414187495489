import { createSlice } from '@reduxjs/toolkit';
import { ICategoriesData } from '../../assets/ts/types';

const initialState: ICategoriesData = {};

const categoriesReducer = createSlice({
    name: 'categories',
    initialState,
    reducers: {
        setCategories(state, action) {
            return action.payload;
        },
    },
});

export const { setCategories } = categoriesReducer.actions;
export default categoriesReducer.reducer;
