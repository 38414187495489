import Actions from '../../../api/actions';
import { store } from '../../../store';
import { update } from '../../../store/reducers/user';
import { IUserNotifications } from '../types';
import convertData from './convert-data';
import getError from './get-error';

const actions = new Actions();

const fetchNotifications = async () => {
    try {
        const result = await actions.fetchNotifications();
        const { detail } = result;

        if (detail) {
            throw new Error(getError({ detail }));
        }

        const notifications = convertData('notifications', result) as IUserNotifications;
        store.dispatch(update({ notificationsAmount: { ...notifications } }));
    } catch (error) {
        const errorStr = (error as Error).message;
        errorStr && console.error(errorStr);
    }
};

export default fetchNotifications;
