import { StylesConfig } from 'react-select';

const BG_COLOR_SELECTED = 'rgba(236, 236, 236, 0.6)';
const COLOR_ACCENT = '#009482';
const COLOR_LINE = 'rgba(236, 236, 236, 0.8)';
const COLOR_LINE_HOVER = '#ececec';
const COLOR_LIGHT = '#AFAFAF';
const COLOR_REGULAR = '#161616';
const COLOR_DANGER = '#d91745';
const COLOR_DISABLED = '#545454';
const COLOR_MENU_BOX_SHADOW = 'rgba(0, 0, 0, 0.25)';
const COLOR_FOCUSED = 'rgba(0, 148, 130, 0.15)';
const COLOR_SELECTED = 'rgba(0, 148, 130, 0.25)';
const TRANSITION_DURATION = '0.2s';

const styles: StylesConfig<any> = {
    control: (provided, { isDisabled, isMulti, isFocused }) => ({
        ...provided,
        border: `1px solid ${isFocused ? COLOR_ACCENT : COLOR_LINE}`,
        minHeight: isMulti ? '38px' : '32px',
        height: isMulti ? 'auto' : '32px',
        backgroundColor: isDisabled ? BG_COLOR_SELECTED : 'transparent',
        boxShadow: 'none',

        ':hover': {
            borderColor: isFocused ? COLOR_ACCENT : COLOR_LINE_HOVER,
        },
    }),
    placeholder: provided => ({
        ...provided,
        color: COLOR_LIGHT,
    }),
    valueContainer: provided => ({
        ...provided,
    }),
    input: provided => ({
        ...provided,
        margin: 0,
    }),
    indicatorSeparator: provided => ({
        ...provided,
        display: 'none',
    }),
    indicatorsContainer: provided => ({
        ...provided,
        height: '100%',
    }),
    menu: provided => ({
        ...provided,
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        margin: '2px 0 0',
        width: 'calc(100% + 2px)',
        border: `1px solid ${COLOR_LINE}`,
        borderTop: 'none',
        boxShadow: `0 4px 4px 0 ${COLOR_MENU_BOX_SHADOW}`,
    }),
    menuList: provided => ({
        ...provided,
        padding: 0,
        zIndex: 2,
    }),
    option: (provided, { selectProps, isFocused, isSelected }) => {
        const creatableOptionLabelClassName = `.${selectProps.classNamePrefix ?? 'select'}__option-new`;
        const backgroundColor = isFocused ? COLOR_FOCUSED : isSelected ? COLOR_SELECTED : 'transparent';

        return {
            ...provided,
            color: COLOR_REGULAR,
            backgroundColor,
            cursor: !isSelected && isFocused ? 'pointer' : 'auto',

            ':active': {
                backgroundColor,
            },

            ':focus': {
                backgroundColor,
            },

            [creatableOptionLabelClassName]: {
                display: 'flex',
                alignItems: 'center',
            },

            [`${creatableOptionLabelClassName}-value`]: {
                padding: '3px 6px',
                margin: '0 5px',
                backgroundColor: BG_COLOR_SELECTED,
                borderRadius: '2px',
                fontSize: '85%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            },
        };
    },
    singleValue: (provided, { isDisabled }) => ({
        ...provided,
        color: isDisabled ? COLOR_DISABLED : 'inherit',
    }),
    multiValue: provided => ({
        ...provided,
        backgroundColor: BG_COLOR_SELECTED,
    }),
    multiValueRemove: provided => ({
        ...provided,
        transition: TRANSITION_DURATION,
        cursor: 'pointer',

        svg: {
            fill: COLOR_LIGHT,
            transition: TRANSITION_DURATION,
        },

        ':hover': {
            backgroundColor: 'transparent',
        },

        ':hover svg': {
            fill: COLOR_DANGER,
        },
    }),
    clearIndicator: provided => ({
        ...provided,
        transition: TRANSITION_DURATION,
        cursor: 'pointer',

        svg: {
            fill: COLOR_LIGHT,
            transition: TRANSITION_DURATION,
        },

        ':hover svg': {
            fill: COLOR_REGULAR,
            opacity: 0.6,
        },
    }),
};

export default styles;
