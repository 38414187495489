import { IDataProductForm, IDataProductFormOption, IDataProductFormSlotsItems, IFormField, IProductForm, IProductFormFieldSlots } from '../../types';
import { convertValidation } from '../forms';

const convertOptions = (options: IDataProductFormOption[]) =>
    options
        ? options.map(option => {
              const { pricelist_id, ...rest } = option;
              return { articul: pricelist_id, ...rest };
          })
        : null;

const convertFieldSlots = (items: IDataProductFormSlotsItems) => {
    const { options, value, show_amount, amount_title, ...rest } = items;
    return {
        amountTitle: amount_title,
        options: convertOptions(options),
        value: value?.map(i => ({ id: i.id, amount: i.count ?? 1 })),
        showAmount: show_amount ?? true,
        ...rest,
    } as IProductFormFieldSlots;
};

const convertCategoryProductForm = ({ fields_main = [], fields_attributes = [], fields_multiple, ...rest }: IDataProductForm) => {
    const [fieldsMain, fieldsAttributes] = [fields_main, fields_attributes].map(fields =>
        fields.map(field => {
            const { block_id, is_disabled, options: fieldOptions, validation, description_field, ...rest } = field;
            return {
                blockId: block_id,
                disabled: is_disabled ?? false,
                options: convertOptions(fieldOptions),
                validation: convertValidation(validation),
                tip: description_field,
                ...rest,
            } as IFormField;
        })
    );

    const form: IProductForm = {
        fieldsMain,
        fieldsAttributes,
        fieldsMultiple: (fields_multiple ?? []).map(field => convertFieldSlots(field)),
        ...rest,
    };

    return form;
};

export default convertCategoryProductForm;
export { convertOptions, convertFieldSlots };
