import cx from 'classnames';
import React, { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../assets/ts/types';
import { toggleOpened } from '../../store/reducers/side-nav';
import LayoutAside from './LayoutAside';
import LayoutMain from './LayoutMain';
import './index.scss';

interface IProps {
    children: ReactElement;
}

const Layout = ({ children }: IProps) => {
    const { sideNav } = useSelector((state: IStore) => state);
    const { opened } = sideNav;
    const dispatch = useDispatch();

    const toggle = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        dispatch(toggleOpened(!opened));
    };

    return (
        <div className={cx(['layout', { '_aside-collapsed': !opened }])}>
            <LayoutAside toggle={toggle} />
            <LayoutMain>{children}</LayoutMain>

            <div className="layout-aside__action">
                <button type="button" className="layout-aside__toggler" onClick={toggle}>
                    <span className="layout-aside__toggler-text">Свернуть панель</span>
                </button>
            </div>
        </div>
    );
};

export default Layout;
