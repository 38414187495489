import { AnyAction, Reducer, combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import { initMessageListener } from 'redux-state-sync';
import applicationReducer from './reducers/app';
import categoriesReducer from './reducers/categories';
import configurationsReducer from './reducers/configurations';
import productsReducer from './reducers/products';
import routerReducer from './reducers/router';
import sideNavReducer from './reducers/side-nav';
import userReducer from './reducers/user';

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['app', 'router'],
    stateReconciler: autoMergeLevel2,
};

const appPersistConfig = {
    key: 'app',
    storage,
};

const routerPersistConfig = {
    key: 'router',
    storage,
};

const appReducer = combineReducers({
    app: persistReducer(appPersistConfig, applicationReducer),
    router: persistReducer(routerPersistConfig, routerReducer),
    user: userReducer,
    products: productsReducer,
    categories: categoriesReducer,
    sideNav: sideNavReducer,
    configurations: configurationsReducer,
});

type RootState = ReturnType<typeof rootReducer>;
const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
    if (action.type === 'app/clearStorage') {
        console.warn('Истекла сессия/необходимо обновить стор.');
        storage.removeItem('persist:root');
    }
    return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
            },
        }),
    devTools: true,
});

initMessageListener(store);

export const persistor = persistStore(store);
