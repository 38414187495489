import { IAppSettingsCoeff, IAppSettingsCoeffs, IDataCoeffs } from '../../types';

const convertCoeffs = (data: IDataCoeffs) => {
    let result: IAppSettingsCoeffs = {};
    data.forEach(item => {
        Object.keys(item).forEach(configurator => {
            result[configurator.toLowerCase()] = Object.values(item[configurator]).map(
                coeff => ({ ...coeff, type: coeff.type.toLowerCase() } as IAppSettingsCoeff)
            );
        });
    });
    return result;
};

export default convertCoeffs;
