/**
 * Функция для повторной загрузки чанка в случае возникновения ChunkLoadError
 *
 * @param componentImport - функция импорта React-компонента
 * @param name - уникальное имя иморта компонента
 *
 * */

const lazyRetry = (componentImport, name) => {
    return new Promise((resolve, reject) => {
        const lazyName = `retry-lazy-${name}-refreshed`;
        const hasRefreshed = JSON.parse(window.sessionStorage.getItem(lazyName) || 'false');

        componentImport()
            .then(component => {
                window.sessionStorage.setItem(lazyName, 'false');
                resolve(component);
            })
            .catch(error => {
                if (!hasRefreshed) {
                    window.sessionStorage.setItem(lazyName, 'true');
                    return window.location.reload();
                }
                reject(error);
            });
    });
};

export default lazyRetry;
