import { TFetchError } from '../types';

const ERROR_DEFAULT = 'Что-то пошло не так.';

const getError = (error: TFetchError) => {
    if (typeof error === 'object') {
        const { name, detail, message, description } = error;
        switch (name) {
            case 'AbortError':
                return '';
            case 'TypeError':
            case 'SyntaxError':
                return ERROR_DEFAULT;
            default:
                if (detail) {
                    return Array.isArray(detail) ? detail.map(err => err.msg).join('<br/>') : detail;
                } else {
                    return message || description || ERROR_DEFAULT;
                }
        }
    } else {
        return error || ERROR_DEFAULT;
    }
};

export default getError;
