import cx from 'classnames';
import React from 'react';
import { IConfigurationFormProduct } from '../../assets/ts/types';
import { ONLY_FOR_PRESALE_TAG_TEXT } from '../../modules/configurator-form/assets';

interface IProps {
    item: IConfigurationFormProduct;
    className?: string;
}

const SingleValueContent = ({ item, className }: IProps) => {
    const { name: optionName, nameHtml: optionNameHtml, price: optionPrice, noPrice, onlyForPresale } = item;
    const price = optionPrice ? optionPrice.toLocaleString() : 'Нет цены';
    const contentClassName = className ?? 'select__option';

    return (
        <>
            <div className={cx([`${contentClassName}-name`, { '_no-price': noPrice }])}>
                {optionNameHtml && !optionNameHtml.includes('<script') ? (
                    <span dangerouslySetInnerHTML={{ __html: optionNameHtml }} />
                ) : (
                    <span>{optionName}</span>
                )}
            </div>
            {onlyForPresale && <div className={`${contentClassName}-tag _presale`}>{ONLY_FOR_PRESALE_TAG_TEXT}</div>}
            {!noPrice && (
                <div className={`${contentClassName}-price-wrapper`}>
                    &mdash;
                    <span className={`${contentClassName}-price`}>
                        {price} {!!optionPrice && ' $'}
                    </span>
                </div>
            )}
        </>
    );
};

export default SingleValueContent;
